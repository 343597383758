import React from 'react'

import Entry from './ApplyButtonEntry'
import Standard from './ApplyButtonStandard'
import Gold from './ApplyButtonGold'

const PLANS = {
  entry: Entry,
  standard: Standard,
  gold: Gold
}

const PlanButton = ({ plan }) => {
  const Plan = PLANS[plan]

  return (
    <center>
      <Plan />
    </center>
  )
}

export default PlanButton
