import ConvertKit from '@widgets/ConvertKit';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import BrewTop from "../../../../content/posts/web-development/.homebrew-intro/brew-top.png";
import SpotlightSearch from "../../../../content/posts/web-development/.homebrew-intro/spotlight-search.png";
import Install from "../../../../content/posts/web-development/.homebrew-intro/install.png";
import * as React from 'react';
export default {
  ConvertKit,
  Img,
  BrewTop,
  SpotlightSearch,
  Install,
  React
};