import React, { useEffect } from 'react'

const QASurvey = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://platform.twitter.com/widgets.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <center>
      <blockquote className='twitter-tweet'>
        <p lang='en' dir='ltr'>
          Which SHELL do you love?
          <a href='https://twitter.com/hashtag/bash?src=hash&amp;ref_src=twsrc%5Etfw'>
            #bash
          </a>{' '}
          <a href='https://twitter.com/hashtag/dash?src=hash&amp;ref_src=twsrc%5Etfw'>
            #dash
          </a>{' '}
          <a href='https://twitter.com/hashtag/zsh?src=hash&amp;ref_src=twsrc%5Etfw'>
            #zsh
          </a>{' '}
          <a href='https://twitter.com/hashtag/fish?src=hash&amp;ref_src=twsrc%5Etfw'>
            #fish
          </a>{' '}
          <a href='https://twitter.com/hashtag/Linux?src=hash&amp;ref_src=twsrc%5Etfw'>
            #Linux
          </a>{' '}
          <a href='https://twitter.com/hashtag/macOS?src=hash&amp;ref_src=twsrc%5Etfw'>
            #macOS
          </a>{' '}
          <a href='https://twitter.com/hashtag/%E9%A7%86%E3%81%91%E5%87%BA%E3%81%97%E3%82%A8%E3%83%B3%E3%82%B8%E3%83%8B%E3%82%A2%E3%81%A8%E7%B9%8B%E3%81%8C%E3%82%8A%E3%81%9F%E3%81%84?src=hash&amp;ref_src=twsrc%5Etfw'>
            #駆け出しエンジニアと繋がりたい
          </a>
        </p>
        &mdash; はむさん (@diveintohacking){' '}
        <a href='https://twitter.com/diveintohacking/status/1449052037551771648?ref_src=twsrc%5Etfw'>
          October 15, 2021
        </a>
      </blockquote>
    </center>
  )
}

export default QASurvey
