import React from 'react'
import LinkButton from './LinkButton'

const urlNormal = 'https://pro.diveintohacking.com/purchase?product_id=3383318'
const url14DayFree =
  'https://pro.diveintohacking.com/purchase?product_id=3400037'

const ApplyButtonStandard = ({ inCampaign }) => {
  const to = inCampaign ? url14DayFree : urlNormal

  return <LinkButton to={to} />
}

export default ApplyButtonStandard
