import { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'

import Cryptor from './Cryptor'
import { specialCode } from '../../../../../packages/blog/gatsby-blog-pages/src/pages/thanks'

const printCampaignCode = newDate => {
  if (newDate === null) return

  const campaignCode = Cryptor.encrypt(`${newDate}T23:59:59`)
  console.log(`?campaignCode=${campaignCode}`)
  const href = window.location.href
  const parser = new URL(href)
  const campaignUrl = `${parser.origin}${parser.pathname}?campaignCode=${campaignCode}`
  console.log(campaignUrl)
  document.location.href = campaignUrl
}

const createNewDate = () => {
  const date = new Date()
  date.setDate(date.getDate() + 7)
  const newDate = date.toJSON().slice(0, 10)
  return newDate
}

const useCampaign = () => {
  const [dueDate, setDueDate] = useState(null)
  const [inCampaign, setInCampaign] = useState(false)
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const campaignCode = params.get('campaignCode')
  let newDate = params.get('newDate')
  const t = params.get('t')

  // newDateの指定は無いがspecialCode付きのURLでアクセスがあった場合は今日から3日後のnewDateを設定する。
  if (newDate === null && t === specialCode) newDate = createNewDate()

  if (newDate && newDate.length === 10) printCampaignCode(newDate)

  useEffect(() => {
    if (campaignCode === null) return

    const decodedDate = Cryptor.decrypt(campaignCode)

    if (decodedDate === null) return
    if (decodedDate === '') return

    const date = new Date(decodedDate)
    setDueDate(date)
    const now = new Date()

    if (now < date) setInCampaign(true)
  }, [campaignCode])

  return { inCampaign, dueDate }
}

export default useCampaign
