import { Link } from 'gatsby';
import { Box, Heading, Button } from 'theme-ui';
import ConvertKit from '@widgets/ConvertKit';
import QASurvey from "../../../../content/posts/plans/QASurvey";
import PlanButton from "../../../../content/posts/plans/PlanButton";
import CampaignMessage from "../../../../content/posts/plans/CampaignMessage";
import Prices from "../../../../content/posts/plans/Prices";
import * as React from 'react';
export default {
  Link,
  Box,
  Heading,
  Button,
  ConvertKit,
  QASurvey,
  PlanButton,
  CampaignMessage,
  Prices,
  React
};