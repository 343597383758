import CryptoJS from 'crypto-js'

export const SECRET_KEY = 'diveintohacking'

class Cryptor {
  static encrypt(string) {
    const encrypted = CryptoJS.AES.encrypt(string, SECRET_KEY)
    const encryptedString = encrypted.toString()
    const result = encodeURIComponent(encryptedString)
    return result
  }

  static decrypt(string) {
    try {
      const uriDecodedString = decodeURIComponent(string)
      const decrypted = CryptoJS.AES.decrypt(uriDecodedString, SECRET_KEY)
      return decrypted.toString(CryptoJS.enc.Utf8)
    } catch {
      return null
    }
  }
}

export default Cryptor
