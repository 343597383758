import React, { useEffect } from 'react'

const QASurvey = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://platform.twitter.com/widgets.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script);
    }
  }, [])

  return (
    <center>
      <blockquote className='twitter-tweet'>
        <p lang='ja' dir='ltr'>
          Udemyを受講されたことのある方へ、お聞きしたいことがあります。ご協力頂けますと幸いです。<span role="img" aria-label="pray">🙏</span>
          <br />
          <br />
          質問：「ぶっちゃけ、Q&amp;Aは質問し辛いですか？」
          <a href='https://twitter.com/hashtag/udemy?src=hash&amp;ref_src=twsrc%5Etfw'>
            #udemy
          </a>{' '}
          <a href='https://twitter.com/hashtag/%E9%A7%86%E3%81%91%E5%87%BA%E3%81%97%E3%82%A8%E3%83%B3%E3%82%B8%E3%83%8B%E3%82%A2%E3%81%A8%E7%B9%8B%E3%81%8C%E3%82%8A%E3%81%9F%E3%81%84?src=hash&amp;ref_src=twsrc%5Etfw'>
            #駆け出しエンジニアと繋がりたい
          </a>{' '}
          <a href='https://twitter.com/hashtag/TwitterSpaceItems?src=hash&amp;ref_src=twsrc%5Etfw'>
            #TwitterSpaceItems
          </a>
        </p>
        &mdash; はむさん (@diveintohacking){' '}
        <a href='https://twitter.com/diveintohacking/status/1434225243699048448?ref_src=twsrc%5Etfw'>
          September 4, 2021
        </a>
      </blockquote>
    </center>
  )
}

export default QASurvey
