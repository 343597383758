import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Link } from 'gatsby'

export const specialCode = '664a4932-9ab9-4631-a3ae-5f527faa5eaf'

const entryPlanUrl = 'https://pro.diveintohacking.com/p/entry#block-86295683'

const fourteenFreeTrialUrl = 'https://pro.diveintohacking.com/purchase?product_id=3704363'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='ご登録ありがとうございます' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header='メールアドレスの本登録が完了しました！'
          subheader=''
        />
        <div>
          この度は、はむさんのオンラインスクールのメルマガにご登録頂き有難うございました。
        </div>
        <div>
          現在、はむさんのオンラインスクールでは、IT業界20年以上の経歴を持つはむさんが丁寧に解説・指導を行っているレクチャー動画を24時間無制限でご受講頂けます。コンテンツは全てはむさんが自ら制作・編集・監修しています。
          また、今なら受講料もオープン記念価格の999円の月額定額プランでご受講頂けます。経験豊富な現役のプログラマのテクニックをこの価格で受講できるのははむさんのオンラインスクールしかないと自負しております。ご興味のある方は是非今のタイミングで始めてみませんか？
        </div>
        <div>
          はむさんのオンラインスクールが提供する
          <strong>
            <Link to={entryPlanUrl} target='_blank' rel='noopener noreferrer'>
              月額定額プラン
            </Link>
          </strong>
          をご希望の方は、
          <strong>
            <Link to={fourteenFreeTrialUrl} target='_blank' rel='noopener noreferrer'>
              こちら
            </Link>
          </strong>
          からお申し込みください。
        </div>
      </Main>
    </Stack>
  </Layout>
)

export default PageContact
