import icons from './default.social.icons'
import extractHostname from './extractHostname'

const attachSocialIcons = items =>
  items &&
  items.length &&
  items.map(item => {
    const originalName = item.name
    const name = (
      originalName || extractHostname(item.url || item)
    ).toLowerCase()
    return {
      name: originalName,
      url: item.url || item,
      ...(icons[name] || icons.default)
    }
  })

export default attachSocialIcons
