import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは！はむさんです！`}</p>
    <p>{`今回は、JavaScript の `}<inlineCode parentName="p">{`let`}</inlineCode>{` というキーワードを紹介します。動画で観たい方はこちらのYouTube動画をご覧ください。`}</p>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "56.25%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "src": "https://www.youtube.com/embed/3j4iFyLdBQA",
        "title": "YouTube video player",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
        "allowFullScreen": true,
        "style": {
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        }
      }}></iframe>{` `}</div>
    <br />
    <p>{`文字で追っかけたい方はこちらをお読みください。`}</p>
    <p>{`では、おもむろにこう書いてみてください。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`let value = 1;
`}</code></pre>
    <p><inlineCode parentName="p">{`let`}</inlineCode>{` は変数 を宣言しますよー！っていう意味のキーワードです。
変数とはデータの入れ物です。
変数には必ず名前を付けます。
今回は `}<inlineCode parentName="p">{`value`}</inlineCode>{` という名前をつけました。
そして、`}<inlineCode parentName="p">{`let`}</inlineCode>{`の右側にイコール `}<inlineCode parentName="p">{`=`}</inlineCode>{` というのがありますね。
これはデータを代入するという働きがあります。
データは今回は数字の`}<inlineCode parentName="p">{`1`}</inlineCode>{`ですね。
要するに、ここでは、`}<inlineCode parentName="p">{`value`}</inlineCode>{`という変数に`}<inlineCode parentName="p">{`1`}</inlineCode>{`を代入しますぜ！という意味になるんですね。
この命令によって、`}<inlineCode parentName="p">{`value`}</inlineCode>{`という箱には`}<inlineCode parentName="p">{`1`}</inlineCode>{`が格納されているという状態を作ることができます。`}</p>
    <p>{`ここまで大丈夫でしょうか？`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`diff --git a/script.js b/script.js
index ef779b9..a82fe83 100644
--- a/script.js
+++ b/script.js
@@ -1,3 +1,5 @@
 console.log('JavaScript is running!');

 let value = 1;
+
+console.log(value);
`}</code></pre>
    <p>{`では、次、 `}<inlineCode parentName="p">{`console.log`}</inlineCode>{` を使ってこの箱を表示させてみます。
ブラウザには何が表示されるでしょう？`}</p>
    <p><inlineCode parentName="p">{`1`}</inlineCode>{` が出力されますね。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`diff --git a/script.js b/script.js
index a82fe83..928d538 100644
--- a/script.js
+++ b/script.js
@@ -3,3 +3,5 @@ console.log('JavaScript is running!');
 let value = 1;

 console.log(value);
+
+let value = 2;
`}</code></pre>
    <p>{`では、次に、上のlet の宣言文をコピーして、`}<inlineCode parentName="p">{`2`}</inlineCode>{`を入れてみました。
すると、どうなるでしょう？`}</p>
    <pre><code parentName="pre" {...{}}>{`Uncaught SyntaxError: Identifier 'value' has already been declared
`}</code></pre>
    <p>{`エラーが表示されますね。シンタックスエラー、文法エラーです。
これは、既に`}<inlineCode parentName="p">{`value`}</inlineCode>{`は変数としての宣言を行っているので、もう宣言することはできませんよっていう感じのことを言われています。
じゃあ、どうやれば、この箱の中身を入れ替えることができるのでしょうか？`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`diff --git a/script.js b/script.js
index 928d538..1379a81 100644
--- a/script.js
+++ b/script.js
@@ -4,4 +4,4 @@ let value = 1;

 console.log(value);

-let value = 2;
+value = 2;
`}</code></pre>
    <p>{`こうやります。 `}<inlineCode parentName="p">{`let`}</inlineCode>{`をとっちゃうんです。
無事、エラーはなくなりました。
ここでは、再度代入しています。
これを再代入と言います。
こういうことができるのが `}<inlineCode parentName="p">{`let`}</inlineCode>{`の特徴になります。
`}<inlineCode parentName="p">{`let`}</inlineCode>{` の中身を覗いてみましょう。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`diff --git a/script.js b/script.js
index 1379a81..91d1d75 100644
--- a/script.js
+++ b/script.js
@@ -2,6 +2,6 @@ console.log('JavaScript is running!');

 let value = 1;

-console.log(value);
-
 value = 2;
+
+console.log(value);
`}</code></pre>
    <p><inlineCode parentName="p">{`console.log`}</inlineCode>{` をずらしてあげます。　
ブラウザには、 `}<inlineCode parentName="p">{`2`}</inlineCode>{`が表示されました。
では、次に、応用編です。
if 文、if 節とかいったりしますが、if を使っていきますね。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`diff --git a/script.js b/script.js
index 91d1d75..06562f6 100644
--- a/script.js
+++ b/script.js
@@ -5,3 +5,7 @@ let value = 1;
 value = 2;

 console.log(value);
+
+if (value === 2) {
+  value = 20;
+}
`}</code></pre>
    <p>{`このような if 文というのを書いてみました。
これは、 `}<inlineCode parentName="p">{`if`}</inlineCode>{` つまり、もしなになにだったら条件が書けるようになります。
`}<inlineCode parentName="p">{`value === 2`}</inlineCode>{` という箇所は、もし`}<inlineCode parentName="p">{`value`}</inlineCode>{`が`}<inlineCode parentName="p">{`2`}</inlineCode>{`だったらという意味になります。
で、その下の、 `}<inlineCode parentName="p">{`value = 20`}</inlineCode>{` は、 `}<inlineCode parentName="p">{`if`}</inlineCode>{` のカッコに渡している条件が成り立つ場合には、この式を評価してね、という命令になります。
この場合成り立つはずなので、 `}<inlineCode parentName="p">{`value`}</inlineCode>{` には `}<inlineCode parentName="p">{`20`}</inlineCode>{`が代入されるはずです。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`diff --git a/script.js b/script.js
index 06562f6..64efc08 100644
--- a/script.js
+++ b/script.js
@@ -4,8 +4,8 @@ let value = 1;

 value = 2;

-console.log(value);
-
 if (value === 2) {
   value = 20;
 }
+
+console.log(value);
`}</code></pre>
    <p>{`20 が出力されました。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`diff --git a/script.js b/script.js
index 64efc08..edd4a04 100644
--- a/script.js
+++ b/script.js
@@ -5,7 +5,7 @@ let value = 1;
 value = 2;

 if (value === 2) {
-  value = 20;
+  let value = 0;
 }

 console.log(value);
`}</code></pre>
    <p>{`では、このようにコードを変えてみるとどうなるでしょうか？`}</p>
    <p>{`なんと、`}<inlineCode parentName="p">{`2`}</inlineCode>{`が表示されましたね。
`}<inlineCode parentName="p">{`if`}</inlineCode>{` の中で value を宣言してみたら、これはさっきは、エラーになったはずなのに、エラーになりませんでした。
これは、どういうことなんでしょうか？
これは、`}<inlineCode parentName="p">{`if`}</inlineCode>{` の 中には、波括弧でブロックされている領域があるんですよね。
これをブロックとかスコープっていうんですが、その側のスコープとは、世界が違うんですよね。
なので、個々のスコープにおいて `}<inlineCode parentName="p">{`let`}</inlineCode>{` を宣言することができる、というのが決まりなんですね。
だから、今回はエラーにならなかったということなんです。
if の外にある`}<inlineCode parentName="p">{`value`}</inlineCode>{`と中にある`}<inlineCode parentName="p">{`value`}</inlineCode>{`は完全に別物になります。`}</p>
    <p>{`ここの概念は凄く大事なのでしっかりイメージできるようにしておきましょう。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`diff --git a/script.js b/script.js
index edd4a04..5fa90db 100644
--- a/script.js
+++ b/script.js
@@ -2,8 +2,6 @@ console.log('JavaScript is running!');

 let value = 1;

-value = 2;
-
 if (value === 2) {
   let value = 0;
 }
`}</code></pre>
    <p>{`次に、 `}<inlineCode parentName="p">{`value = 2`}</inlineCode>{` を削除します。すると、
この場合は`}<inlineCode parentName="p">{`1`}</inlineCode>{`が出力されますね。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`diff --git a/script.js b/script.js
index 5fa90db..3419d8d 100644
--- a/script.js
+++ b/script.js
@@ -1,6 +1,6 @@
 console.log('JavaScript is running!');

-let value = 1;
+let value = 2;

 if (value === 2) {
   let value = 0;
`}</code></pre>
    <p>{`こうするとどうなるでしょうか？
value は何が出力されるでしょうか？
出力は、`}<inlineCode parentName="p">{`2`}</inlineCode>{`です。`}</p>
    <p>{`問題ないですね？`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`diff --git a/script.js b/script.js
index ef3b23d..8bbd3cd 100644
--- a/script.js
+++ b/script.js
@@ -3,7 +3,7 @@ console.log('JavaScript is running!');
 let value = 2;

 if (value === 2) {
-  let value = 20;
+  value = 20;
 }

 console.log(value);
`}</code></pre>
    <p>{`じゃあ、こうするとどうなるでしょう？
`}<inlineCode parentName="p">{`20`}</inlineCode>{` が出力されます。`}</p>
    <p>{`もう慣れましたかね？このスコープの概念。`}</p>
    <p>{`はい、では、ついでに、 `}<inlineCode parentName="p">{`let`}</inlineCode>{`の話ではないんですが、
`}<inlineCode parentName="p">{`if`}</inlineCode>{` について深堀りします。
実は、`}<inlineCode parentName="p">{`if`}</inlineCode>{` の条件が成り立つ時に実行する内容が今みたいに 1 行しか無い場合はこう書けます。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`diff --git a/script.js b/script.js
index 8bbd3cd..c2282fd 100644
--- a/script.js
+++ b/script.js
@@ -2,8 +2,6 @@ console.log('JavaScript is running!');

 let value = 2;

-if (value === 2) {
-  value = 20;
-}
+if (value === 2) value = 20;

 console.log(value);
`}</code></pre>
    <p>{`但し、このように書くことはできません。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`diff --git a/script.js b/script.js
index c2282fd..52c8b15 100644
--- a/script.js
+++ b/script.js
@@ -2,6 +2,6 @@ console.log('JavaScript is running!');

 let value = 2;

-if (value === 2) value = 20;
+if (value === 2) let value = 20;

 console.log(value);
`}</code></pre>
    <pre><code parentName="pre" {...{}}>{`Uncaught SyntaxError: Lexical declaration cannot appear in a single-statement context
`}</code></pre>
    <p>{`というエラーが表示されましたね。`}</p>
    <p>{`はい、これは、`}<inlineCode parentName="p">{`let`}</inlineCode>{` の宣言に関しては、ワンラインで書くことができないという、JavaScript の制約に引っかかるからですね。`}</p>
    <p>{`なので、 `}<inlineCode parentName="p">{`let`}</inlineCode>{` は書けません。`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-diff"
      }}>{`diff --git a/script.js b/script.js
index 52c8b15..ef3b23d 100644
--- a/script.js
+++ b/script.js
@@ -2,6 +2,8 @@ console.log('JavaScript is running!');

 let value = 2;

-if (value === 2) let value = 20;
+if (value === 2) {
+  let value = 20;
+}

 console.log(value);
`}</code></pre>
    <p>{`だから、 `}<inlineCode parentName="p">{`let`}</inlineCode>{`を書きたくなったら、ブロック、つまり、波括弧で囲ってあげるしか無いんです。`}</p>
    <p>{`如何だったでしょうか。`}</p>
    <p>{`以上が、`}<inlineCode parentName="p">{`let`}</inlineCode>{`  とスコープでした。
この `}<inlineCode parentName="p">{`let`}</inlineCode>{`、JavaScript では、よく使いますので、是非、ここで基本をしっかり押さえておきましょう。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      