import React from 'react'
import LinkButton from './LinkButton'

const urlStandard = 'https://wondrous-experimenter-2366.ck.page/aeb06d1a2c'

const ApplyButtonEntry = ({ inCampaign }) => {
  return <LinkButton to={urlStandard} />
}

export default ApplyButtonEntry
