import React from 'react'
import { Button } from 'theme-ui'

const LinkButton = ({ to }) => {
  return (
    <a
      href={to}
      target='_blank'
      rel='noopener noreferrer'
      style={{ textDecoration: 'none' }}
    >
      <Button sx={{ borderRadius: 5 }}>
        <strong>今すぐ始めてみる</strong>
      </Button>
    </a>
  )
}

export default LinkButton
