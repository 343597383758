import React from 'react';

import useCampaign from './useCampaign';

const CampaignMessage = () => {
  const { dueDate, inCampaign } = useCampaign();

  if (dueDate === null) return <></>;
  if (inCampaign === false) return <></>;

  return (
    <div>
      ※今なら<strong>キャンペーン中</strong>につき、<strong>14日間</strong>
      の無料体験付きでお申込み頂けます。サービスが気に入らなかった場合でも、
      <strong>14日以内に解約すれば料金の請求は発生しません。</strong>
      尚、<strong>キャンペーン</strong>は、日本時間の
      <strong>
        {dueDate.getFullYear()}年{dueDate.getMonth() + 1}月{dueDate.getDate()}日
        {dueDate.getHours()}時{dueDate.getMinutes()}分{dueDate.getSeconds()}秒
        まで
      </strong>
      有効です。
      <br />
      <br />
    </div>
  );
};

export default CampaignMessage;
