import React, { useEffect } from 'react'

const ConvertKit = ({ dataUid }) => {
  const name = `ck-activated-data-uid-${dataUid}`

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    const ckactivated = body.getAttribute(name)

    if (!ckactivated) {
      const script = document.createElement('script')
      script.src = `https://wondrous-experimenter-2366.ck.page/${dataUid}/index.js`
      script.setAttribute('data-uid', dataUid)
      document.body.appendChild(script)
      body.setAttribute(name, true)
    }
  }, [])

  return <></>
}

export default ConvertKit
