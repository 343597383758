import React from 'react'

const prices = {
  minimum: '999',
  entry: '2,000',
  standard: '3,000',
  gold: '16,000'
}

const Prices = ({ plan }) => {
  return <center><b>{prices[plan]} 円 (税込)</b></center>
}

export default Prices
