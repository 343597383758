import React from 'react'
import { Button } from 'theme-ui'

const urlNormal = 'https://pro.diveintohacking.com/purchase?product_id=3434532'
const url14DayFree =
  'https://pro.diveintohacking.com/purchase?product_id=3434536'

const ApplyButtonGold = ({ inCampaign }) => {
  const to = inCampaign ? url14DayFree : urlNormal

  return (
    <a
      href={to}
      target='_blank'
      rel='noopener noreferrer'
      style={{ textDecoration: 'none' }}
    >
      <Button sx={{ borderRadius: 0 }}>
        <strong>お申込みページへ移動</strong>
      </Button>
    </a>
  )
}

export default ApplyButtonGold
